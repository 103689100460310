<template>
  <a-card :title="$t('stats.detailedStats')" :scroll="{ x: true, y: false }">
    <a-table :columns="columns" :data-source="rows">
      <a slot="user" slot-scope="text">{{ text }}</a>
      <span slot="customTitleUser"
        ><a-icon type="user-o" /> {{ $t("menu.utilisateur") }}</span
      >
      <a slot="date" slot-scope="text">{{ text }}</a>
      <span slot="customTitleDate"
        ><a-icon type="calendar-o" /> {{ $t("assiduite.date") }}</span
      >
      <a slot="info" slot-scope="text">{{ text }}</a>
      <span slot="customTitleInfo"
        ><a-icon type="number-o" /> {{ $t("stats.nb_connections") }}</span
      >
      <div slot="expandedRowRender" slot-scope="record">
        <div class="justify-content-center row">
          <table class="table col-md-6 offset-md-3">
            <tr>
              <th>#</th>
              <th>{{ $t("stats.connect") }}</th>
            </tr>
            <tr
              v-for="(connect, index) in record.details"
              :key="record.id + 'connect_' + index + '_' + connect"
            >
              <td>
                {{ index }}
              </td>
              <td>{{ moment(connect).format("HH:mm:ss") }}</td>
            </tr>
          </table>
        </div>
      </div>
    </a-table>
  </a-card>
</template>
<script>
import moment from "moment";
const columns = [
  {
    dataIndex: "user",
    key: "user",
    slots: { title: "customTitleUser" },
    scopedSlots: { customRender: "user" },
  },
  {
    dataIndex: "date",
    key: "date",
    slots: { title: "customTitleDate" },
    scopedSlots: { customRender: "date" },
  },
  {
    dataIndex: "info",
    key: "info",
    slots: { title: "customTitleInfo" },
    scopedSlots: { customRender: "info" },
  },
];

//function getExpanRow(connects, disconnects) {
//  let rows = {
//    connects: [],
//    disconnects: [],
//    durations: [],
//  };
//  if (disconnects.length == 0) return rows;
//  const nc = connects.length;
//  const nd = disconnects.length;
//  const n = nc > nd ? nd : nc;
//  connects.sort((a, b) => a - b);
//  disconnects.sort((a, b) => a - b);
//  for (let t = 0; t < n; t++) {
//    const disconnect = moment(disconnects[t]);
//    const connect = moment(connects[t]);
//    const duration = helpers.getDuration(disconnect.diff(connect));
//    rows.disconnects.push(disconnect.format("hh:mm"));
//    rows.connects.push(connect.format("hh:mm"));
//    rows.durations.push(duration);
//  }
//  return rows;
//}
export default {
  props: {
    data: Array,
  },
  data() {
    return {
      columns,
    };
  },
  computed: {
    rows() {
      if (!(this.data && this.data.length > 0)) return [];

      return this.data.map((userActivity, index) => {
        const { connects } = userActivity;
        return {
          key: userActivity.user.id + "_" + index,
          user: userActivity.user.name,
          info: connects.length,
          date: moment(userActivity.createdAt).format("DD-MM-YYYY"),
          details: connects,
        };
      });
    },
  },
  methods: {
    moment,
  },
};
</script>
