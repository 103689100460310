<template>
  <div>
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom col-xl-12"
      :height="350"
      width="100%"
      :options="options"
      :series="series"
    />
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "SecondaryStats",
  props: {
    series: Array,
    options: Object,
  },
};
</script>
