<template>
  <a-card :title="$t('menu.connection')">
    <a slot="extra">
      <a-switch
        :checked-children="$t('connected.online')"
        :un-checked-children="$t('connected.all')"
        default-checked
        @click="changeStatus"
    /></a>
    <a-col v-if="loading" :span="16" :offset="11">
      <a-spin size="large" />
    </a-col>

    <div v-else>
      <a-descriptions size="small" :column="2">
        <a-descriptions-item :label="$t('connected.online')">
          <span class="font-size-20 font-weight-bold">{{ count }}</span>
        </a-descriptions-item>
        <a-descriptions-item :label="secondaryTitle">
          <span class="font-size-20 font-weight-bold">{{ totalCount }}</span>
        </a-descriptions-item>
      </a-descriptions>
      <div class="row">
        <div class="col-md-12">
          <a-input-search
            :placeholder="$t('personnel.chercher')"
            @change="onSearch"
            enter-button
            allowClear
            @search="onSearch"
          >
            <a-icon type="x" />
          </a-input-search>
        </div>
      </div>
    </div>
    <a-list
      class="demo-loadmore-list"
      item-layout="horizontal"
      :data-source="users_on_connection_list"
      :pagination="{
        position: 'bottom',
        hideOnSinglePage: true,
        simple: true,
        defaultPageSize: pageSize,
      }"
    >
      <a-list-item
        slot="renderItem"
        slot-scope="item"
        :class="selectedUser == item._id ? 'bg-secondary bg-gradient' : ''"
      >
        <a-list-item-meta @click="select_user(item._id)">
          <a slot="title">
            {{ item.firstName + " " + item.lastName }}
          </a>
          <span slot="avatar">
            <a-badge dot :color="item.online ? 'green' : 'red'">
              <a-avatar
                :src="
                  item.photo
                    ? settings.base_url + '/' + item.photo.replaceAll('\\', '/')
                    : '-'
                "
              />
            </a-badge>
          </span>
        </a-list-item-meta>
      </a-list-item>
    </a-list>
  </a-card>
</template>
<script>
/* eslint-disable */
import VueChartist from "v-chartist";
import { mapState } from "vuex";
export default {
  name: "card-count",
  components: {
    "vue-chartist": VueChartist,
  },
  props: {
    title: { required: true },
    usersList: { required: true },
    secondaryTitle: { required: true },
    loading: { required: true },
    messageEnabled: { required: true },
  },
  computed: {
    ...mapState(["settings"]),
    count: function () {
      return this.usersList.filter((user) => user.online).length;
    },
    pageSize: function () {
      //admin tab contain one chart , limit page per size to match chart height
      return this.title.includes("admin") ? 4 : 9;
    },
    totalCount: function () {
      return this.usersList.length;
    },
    users_on_connection_list: function () {
      return this.users_filtred
        .sort((x, y) => {
          return x.online === y.online ? 0 : x.online ? -1 : 1;
        })
        .filter((user) => !this.status_selected || user.online);
    },
    users_filtred: function () {
      return this.usersList.filter((user) =>
        (user.firstName + " " + user.lastName)
          .toLowerCase()
          .includes(this.filterText)
      );
    },
  },
  data: function () {
    return {
      status_selected: true, // true for online, false for offline,
      selectedUser: "",
      filterText: "",
    };
  },
  methods: {
    select_user(id) {
      if (this.selectedUser == id) {
        this.selectedUser = "";
        this.$emit("deleteSlected");
      } else {
        this.selectedUser = id;
        this.$emit("updateSlected", id);
      }
    },
    changeStatus: function (checked, _) {
      this.status_selected = checked;
    },
    onSearch: function (e) {
      const text =
        typeof e === "string"
          ? e
          : typeof e === "object"
          ? e.srcElement.value || ""
          : "";
      this.filterText = text;
    },
    resetSearch: function () {
      this.filterText = "";
    },
  },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
