<template>
  <div>
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom col-xl-12"
      :height="350"
      width="100%"
      :options="options"
      :series="series"
    />
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "TeacherStats",
  props: {
    series: Array,
    options: Object,
  },
  created() {
    this.dates = this.initDates;
  },
  watch: {
    series: function (series, _) {
      console.info("series: ", series);
    },
    options: function (options, _) {
      console.info("options: ", options);
    },
  },
  computed: {
    optionsFiltered() {
      if (this.series && this.series.length > 0 && this.series[0].length > 0) {
        let earliest = this.series[0].data.reduce((earliest, current) => {
          return current[0] < earliest[0] ? current : earliest;
        });
        const start = earliest[0];
        return {
          ...this.options,
          xaxis: {
            type: "datetime",
            min: start.valueOf(),
            tickAmount: 6,
          },
        };
      } else return this.options;
    },
  },
};
</script>
