import apiClient from "@/services/axios";
import moment from "moment";
import _ from "lodash";

const getDuration = (time, onMinute = false) => {
  const hours = Math.floor(time / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);
  if (onMinute) {
    return (hours + (minutes / 60 + seconds / 3600)).toFixed(2);
  }
  const hourStr = hours > 0 ? hours + "h" : "";
  const minuteStr = minutes > 0 ? minutes + "m" : "";
  const secondStr = seconds > 0 ? seconds + "s" : "";

  return hourStr + minuteStr + secondStr;
};
function calculateTimeSpent(connects, disconnects, str = true) {
  if (disconnects.length == 0) return "--";
  const nc = connects.length;
  const nd = disconnects.length;
  const n = nc > nd ? nd : nc;
  connects.sort((a, b) => a - b);
  disconnects.sort((a, b) => a - b);
  let time = 0;
  for (let t = 0; t < n; t++) {
    time += new Date(disconnects[t]) - new Date(connects[t]);
  }
  if (str) {
    const fullStr = getDuration(time);
    return fullStr == "" ? "--" : fullStr;
  } else return getDuration(time, true);
}

function transforMainStatData(dataList, selected) {
  const data = [...dataList];
  //return [
  //  {
  //    data:
  //      selected == ""
  //        ? countTimestampsPerDay(data.map((item) => item.createdAt))
  //        : data.map((item) => [
  //            moment(item.createdAt, "YYYY-MM-DD HH:mm:ss").format(
  //              "YYYY-MM-DD"
  //            ),
  //            calculateTimeSpent(item.connects, item.disconnects, false),
  //          ]),
  //  },
  //];
  //this commented because it causes an infinit loop when filtering data on one user
  return [
    {
      data: countTimestampsPerDay(data.map((item) => item.createdAt)),
    },
  ];
}

const MainStatsoptions = (
  minDate,
  title,
  xTitle,
  yTitle,
  connectionTranslated
) => ({
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  title: {
    text: title,
    align: "center",
    margin: 10,
    offsetX: 30,
    offsetY: -10,
    floating: true,
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: undefined,
      color: "#263238",
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    title: {
      text: xTitle,
    },
    type: "datetime",
    min: minDate,
    tickAmount: 6,
    labels: {
      formatter: function (val, timestamp) {
        return moment(timestamp).format("DD MMM YYYY");
      },
    },
  },
  yaxis: {
    title: {
      text: yTitle,
    },
  },
  tooltip: {
    y: {
      formatter: function (y) {
        if (typeof y !== "undefined") {
          return y.toFixed(0) + " " + connectionTranslated;
        }
        return y;
      },
      title: {
        text: yTitle,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
});
const SecondaryStatsOption = (labels, title, xTitle, yTitle, target) => {
  return {
    chart: {
      height: 350,
      type: "line",
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: title,
      align: "center",
      margin: 10,
      offsetX: 30,
      offsetY: -10,
      floating: true,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    labels: labels,
    markers: {
      size: 0,
    },
    xaxis: {
      title: {
        text: xTitle,
      },
      tickAmount: 6,
    },
    yaxis: {
      title: {
        text: yTitle,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + target;
          }
          return y;
        },
      },
    },
  };
};
function toTimestamp(dateString) {
  return moment(dateString, "YYYY-MM-DD").valueOf();
}

// Function to count occurrences per day
function countTimestampsPerDay(dateList) {
  let dateCounts = {};

  // Count occurrences per day
  dateList.forEach((dateTime) => {
    let date = moment(dateTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"); // Extract just the date
    dateCounts[date] = (dateCounts[date] || 0) + 1;
  });

  // Convert to required format [timestamp, count]
  return Object.entries(dateCounts).map(([date, count]) => [
    toTimestamp(date),
    count,
  ]);
}

async function fetchMainStats(
  on,
  selected,
  dateStart,
  dateEnd,
  messagesHelper,
  translateHelper
) {
  let raw = [];
  try {
    if (selected && selected.length > 0) {
      const res = await apiClient.post("/connected", {
        on,
        dateStart,
        dateEnd,
        selected,
      });
      raw = res.data;
    } else {
      const res = await apiClient.post("/connected", {
        on,
        dateStart,
        dateEnd,
      });
      raw = res.data;
    }
  } catch (error) {
    messagesHelper.error(translateHelper("error.aucUser"));
  } finally {
    return raw;
  }
}

async function fetchSecondaryStats(
  on,
  selected,
  dateStart,
  dateEnd,
  messagesHelper,
  translateHelper
) {
  let raw = [];
  try {
    const Api_url =
      on == "parent" ? "lms/seen/filter" : "lms/seen/posted_material";
    if (selected && selected.length > 0) {
      const res = await apiClient.post(Api_url, {
        dateStart,
        dateEnd,
        selected,
      });
      raw = res.data;
    } else {
      const res = await apiClient.post(Api_url, {
        dateStart,
        dateEnd,
      });
      raw = res.data;
    }
  } catch (error) {
    messagesHelper.error(translateHelper("error.aucUser"));
  } finally {
    return raw;
  }
}

function formatSecondaryData(data, labels) {
  const courses = data
    .filter((seen) => seen.fromCourse)
    .map((item) => moment(item.createdAt).format("DD MMMM yyyy"));
  const exercises = data
    .filter((seen) => !seen.fromCourse)
    .map((item) => moment(item.createdAt).format("DD MMMM yyyy"));
  let coursesCount = [];
  let exercisesCount = [];
  for (const label of labels) {
    coursesCount.push(courses.filter((date) => date == label).length);
    exercisesCount.push(exercises.filter((date) => date == label).length);
  }
  return [
    {
      name: "Exercises",
      type: "line",
      data: exercisesCount,
    },
    {
      name: "Courses",
      type: "line",
      data: coursesCount,
    },
  ];
}
export default {
  fetchMainStats,
  fetchSecondaryStats,
  MainStatsoptions,
  SecondaryStatsOption,
  countTimestampsPerDay,
  formatSecondaryData,
  getDuration,
  calculateTimeSpent,
  transforMainStatData,
};
