<template>
  <div class="container-fluid">
    <a-row :gutter="[16, 16]">
      <a-col :span="19">
        <a-card
          style="width: 100%"
          :tab-list="tabList"
          :active-tab-key="activeKey"
          @tabChange="callback"
        >
          <a slot="tabBarExtraContent" href="#">
            <a-range-picker
              :ranges="{
                Today: [moment().startOf('day'), moment().endOf('day')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
                'Last Three Months': [
                  moment().subtract(3, 'months').startOf('month'),
                  moment().endOf('month'),
                ],
              }"
              show-time
              :defaultValue="initDates"
              :format="dateFormat"
              @change="onChange"
          /></a>

          <main-stats
            :key="'M' + loadKey"
            :series="MainStatsData"
            :options="MainStatsoptions"
            class="row mw-100"
            v-if="MainStatsDataReady && noFilterOutgoing"
          />
          <div v-else class="card-body row mw-100">
            <a-spin />
          </div>
          <secondary-stats
            v-if="
              (activeKey == '1' || activeKey == '2') && secondaryStatsDataReady
            "
            :key="'S' + loadKey"
            :series="SecondaryStatsData"
            :options="SecondaryStatsOption"
          />
          <div
            v-else-if="activeKey == '1' || activeKey == '2'"
            class="card-body row mw-100"
          >
            <a-spin />
          </div>
        </a-card>
      </a-col>
      <a-col :span="5">
        <connected-users
          v-if="activeKey == '1'"
          :key="'CU1' + loadKey"
          :loading="loadingInfo.parents"
          :users-list="parents_list"
          :secondary-title="$t('liste.total')"
          :title="$t('sms.parents')"
          :messageEnabled="true"
          @updateSlected="updateSelected"
          @deleteSlected="deleteSelected"
        >
        </connected-users>
        <connected-users
          v-else-if="activeKey == '2'"
          :key="'CU2' + loadKey"
          :loading="loadingInfo.teachers"
          :users-list="teachers_list"
          :secondary-title="$t('liste.total')"
          :title="$t('actualite.teachers')"
          :messageEnabled="false"
          @updateSlected="updateSelected"
          @deleteSlected="deleteSelected"
        >
        </connected-users>
        <connected-users
          v-else-if="activeKey == '3'"
          :key="'CU3' + loadKey"
          :loading="loadingInfo.admins"
          :users-list="admins_list"
          :secondary-title="$t('liste.total')"
          :title="$t('connected.school_user')"
          :messageEnabled="false"
          @updateSlected="updateSelected"
          @deleteSlected="deleteSelected"
        >
        </connected-users>
      </a-col>
      <a-col :span="24">
        <detailed-stats
          :data="filteredConnectedData"
          v-if="MainStatsDataReady"
        />
        <div v-else class="card-body">
          <a-spin />
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
/* eslint-disable */
import ConnectedUsers from "./components/ConnectedUsers";
import MainStats from "./components/MainStats";
import SecondaryStats from "./components/SecondaryStats";
import DetailedStats from "./components/DetailedStats";
import helpers from "./helpers";
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
function findOldestDate(dates) {
  return dates.reduce((oldest, current) => {
    return new Date(oldest) < new Date(current) ? oldest : current;
  });
}
export default {
  name: "UsersStats",
  components: {
    ConnectedUsers,
    MainStats,
    SecondaryStats,
    DetailedStats,
  },
  data() {
    return {
      tabList: [
        {
          key: "1",
          tab: this.$t("actualite.parents"),
        },
        {
          key: "2",
          tab: this.$t("actualite.teachers"),
        },
        {
          key: "3",
          tab: this.$t("connected.school_user"),
        },
      ],
      initDates: [
        moment().subtract(3, "months").startOf("month"),
        moment().endOf("month"),
      ],
      dates: [
        moment().subtract(3, "months").startOf("month"),
        moment().endOf("month"),
      ],
      dateFormat: "YYYY/MM/DD HH:mm",
      MainStatsDataReady: false,
      rawConnectedData: [],
      secondaryStatsDataReady: false,
      rawSecondaryData: [],
      activeKey: "1",
      selectedUser: "",
      loadKey: 0,
      loadingInfo: false,
      noFilterOutgoing: true,
      parents_list: [],
      teachers_list: [],
      admins_list: [],
      options: {
        mainStats: {},
        secondaryStats: {},
      },
    };
  },
  computed: {
    ...mapState(["settings"]),
    filteredConnectedData() {
      this.noFilterOutgoing = false;
      let returns = undefined;
      if (this.MainStatsDataReady) {
        if (this.selectedUser == "") returns = this.rawConnectedData;
        else {
          const rawList = [...this.rawConnectedData]; // Reset filtering before applying new filter :: keep [...] copying to avoid infinit loop
          rawList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          returns = rawList.filter(
            (connectsItem) => connectsItem.user.id == this.selectedUser
          );
        }
      } else returns = [];
      this.noFilterOutgoing = true;
      return returns;
    },
    filteredawSecondaryData() {
      if (this.secondaryStatsDataReady) {
        if (this.selectedUser == "") return this.rawSecondaryData;
        else {
          const rawList = [...this.rawSecondaryData]; // Reset filtering before applying new filter :: keep [...] copying to avoid infinit loop
          return rawList.filter((seenItem) =>
            this.activeKey == "1"
              ? seenItem.parent == this.selectedUser
              : seenItem.teacher == this.selectedUser
          );
        }
      } else return [];
    },
    SecondaryDatalabels() {
      let labels = this.filteredawSecondaryData.map((item) => {
        const date = item.createdAt;
        return moment(date).format("DD MMMM yyyy");
      });
      return [...new Set(labels)];
    },
    MainStatsData() {
      return helpers.transforMainStatData(
        this.rawConnectedData,
        this.selectedUser
      );
    },
    SecondaryStatsData() {
      return helpers.formatSecondaryData(
        this.filteredawSecondaryData,
        this.SecondaryDatalabels
      );
    },
    MainStatsoptions() {
      return helpers.MainStatsoptions(
        this.minDate,
        //this.selectedUserName == ""
        //  ? this.$t("stats.mainStats")
        //  : this.$t("stats.mainStatSelected", { name: this.selectedUserName }),
        this.$t("stats.mainStats"),
        this.$t("assiduite.date"),
        this.$t("stats.nb_connections"),
        this.$t("stats.connects")
      );
    },
    selectedUserName() {
      if (this.selectedUser == "") return "";
      else {
        const user = this.rawConnectedData.find(
          (userInfo) => userInfo.user.id == this.selectedUser
        );
        return user ? user.user.name : "";
      }
    },
    adminsSettings() {
      return this.settings.connected.admins;
    },
    parentsSettings() {
      return this.settings.connected.parents;
    },
    teachersSettings() {
      return this.settings.connected.teachers;
    },
    SecondaryStatsOption() {
      const secondaryStats = `stats.secondaryStats${
        this.activeKey == "1" ? "parent" : "teacher"
      }`;
      const secondaryStatSelected = `stats.secondaryStatSelected${
        this.activeKey == "1" ? "parent" : "teacher"
      }`;
      const seen = `stats.${this.activeKey == "1" ? "seen" : "published"}`;
      return helpers.SecondaryStatsOption(
        this.SecondaryDatalabels,
        this.selectedUserName == ""
          ? this.$t(secondaryStats)
          : this.$t(secondaryStatSelected, {
              name: this.selectedUserName,
            }),
        this.$t("assiduite.date"),
        this.$t("sms.newCourse"),
        this.$t(seen)
      );
    },
    minDate() {
      if (this.rawConnectedData.length == 0)
        return new Date("01 Jan 2023").getTime();
      else {
        const dates = this.rawConnectedData.map((fcd) => fcd.createdAt);
        const oldest = findOldestDate(dates)[0];
        return moment(new Date(oldest).getTime())
          .startOf("day")
          .format("YYYY-MM-DD");
      }
    },
  },
  watch: {
    adminsSettings: function (connectedAdminList, oldconnectedAdminList) {
      this.admins_list = this.admins_list.map((admin) => {
        let user = {
          ...admin,
        };
        user.online = connectedAdminList.includes(user._id);
        return user;
      });
    },
    parentsSettings: function (connectedParentList, oldconnectedParentList) {
      this.parents_list = this.parents_list.map((parent) => {
        let user = {
          ...parent,
        };
        user.online = connectedParentList.includes(user._id);
        return user;
      });
    },
    teachersSettings: function (connectedTeacherList, oldconnectedTeacherList) {
      this.teachers_list = this.teachers_list.map((teacher) => {
        let user = {
          ...teacher,
        };
        user.online = connectedTeacherList.includes(user._id);
        return user;
      });
    },
  },
  async created() {
    this.loadingInfo = true;
    // parents
    apiClient
      .post("/parents/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $project: {
              father: {
                firstName: 1,
                lastName: 1,
              },
              mother: {
                firstName: 1,
                lastName: 1,
              },
              _id: 1,
              photo: 1,
            },
          },
        ],
      })
      .then(({ data }) => {
        this.parents_list = data.map((parent) => {
          let user = {
            online: this.settings.connected.parents.includes(parent._id),
            firstName:
              parent.father.firstName + " " + parent.father.lastName + " / ",
            lastName: parent.mother.firstName + " " + parent.mother.lastName,
            photo: parent.user,
            _id: parent._id,
          };
          return user;
        });
      });

    // teachers
    apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $project: {
              employee: {
                firstName: 1,
                lastName: 1,
                photo: 1,
              },
              _id: 1,
            },
          },
        ],
      })
      .then(({ data }) => {
        this.teachers_list = data.map((teacher) => {
          let user = {
            online: this.settings.connected.teachers.includes(teacher._id),
            firstName: teacher.employee.firstName,
            lastName: teacher.employee.lastName,
            photo: teacher.employee.photo,
            _id: teacher._id,
          };
          return user;
        });
      });

    // admins
    apiClient
      .get("/autorisation/buildingUsers")
      .then((res) => {
        this.admins_list = res.data.reduce((toReturn, user) => {
          if (user.status === "active") {
            let firstName = "";
            let lastName = "";
            if (
              (user.firstName && user.firstName.length > 0) ||
              (user.lastName && user.lastName.length > 0)
            ) {
              firstName = user.firstName || "";
              lastName = (user.lastName || "") + ` (${user.userName})`;
            } else {
              firstName = this.$t("connected.noname");
              lastName = ` (${user.userName})`;
            }
            toReturn.push({
              online: this.settings.connected.admins.includes(user._id),
              firstName,
              lastName,
              photo: user.avatar,
              _id: user._id,
            });
          }
          return toReturn;
        }, []);
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.aucUser"));
      });
    await this.updateConnectsData();
    await this.updateSecondaryData();
    this.loadingInfo = false;
  },
  methods: {
    moment,
    callback: async function (key) {
      this.activeKey = key;
      this.loadKey += 1;
      this.selectedUser = "";
      await this.updateConnectsData();
      await this.updateSecondaryData();
    },
    deleteSelected() {
      this.selectedUser = "";
    },
    updateSelected(value) {
      this.selectedUser = value;
    },
    async updateConnectsData() {
      this.MainStatsDataReady = false;
      const data = await helpers.fetchMainStats(
        this.activeKey == "1"
          ? "parent"
          : this.activeKey == "2"
          ? "teacher"
          : "admin",
        "",
        this.dates[0],
        this.dates[1],
        this.$message,
        this.$t
      );
      const dataUpdated = this.fixConnectedDataNames(data);
      this.rawConnectedData = [...dataUpdated];
      this.rawConnectedData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      this.MainStatsDataReady = true;
    },
    async updateSecondaryData() {
      this.secondaryStatsDataReady = false;
      if (this.activeKey != "3") {
        let data = await helpers.fetchSecondaryStats(
          this.activeKey == "1" ? "parent" : "teacher",
          "",
          this.dates[0],
          this.dates[1],
          this.$message,
          this.$t
        );
        if (this.activeKey == "2") {
          let temp = [];
          temp = data.courses.map((course) => ({
            ...course,
            fromCourse: course,
          }));
          temp = temp.concat(
            data.homeworks.map((homework) => ({
              ...homework,
              fromHomework: homework,
            }))
          );
          temp = temp.concat(
            data.quizes.map((quiz) => ({
              ...quiz,
              fromQuiz: quiz,
            }))
          );
          data = temp;
        }
        this.rawSecondaryData = [...data];
      }
      this.secondaryStatsDataReady = true;
    },
    fixConnectedDataNames(data) {
      let userList = [];
      if (this.activeKey == "1") userList = this.parents_list;
      if (this.activeKey == "2") userList = this.teachers_list;
      if (this.activeKey == "3") userList = this.admins_list;
      return data.map((elem) => {
        const user = userList.find((userInfo) => userInfo._id == elem.user.id);
        if (user)
          return {
            ...elem,
            user: {
              ...elem.user,
              name: user.firstName + " " + user.lastName,
            },
          };
        else return elem;
      });
    },
    async onChange(dates, dateStrings) {
      this.dates = dates;
      await this.updateConnectsData();
      await this.updateSecondaryData();
    },
  },
};
</script>
